import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImageRounded from '../../components/BackgroundImageRounded';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <BackgroundImageRounded className="hero-bg">
      <Container>
        <Row className="hero">
          <Col lg={8}>
            <h1>{t('heroH1')}</h1>
            <p>{t('heroH3')}</p>
            {/* <p>{t('contactFormParagraph')}</p> */}
            {/* <input placeholder={t('contactFormPlaceholder')} />
            <br />
            <Button type="button" className="btn btn-success">
              {t('contactFormButton')}
            </Button> */}
          </Col>
        </Row>
      </Container>
    </BackgroundImageRounded>
  );
};
