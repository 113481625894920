import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import stones from '../../images/kiviainekset.png';
import transport from '../../images/kuljetus.png';
import landScaping from '../../images/maanrakennus.png';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container className="what-we-do">
      <Row>
        <Col lg={4}>
          <div className="card">
            <img
              src={stones}
              alt={t('whatWeDoGravelH4')}
              className="card-img-top"
            />
            <div className="card-body">
              <h4 className="card-title">{t('whatWeDoGravelH4')}</h4>
              <h5>{t('whatWeDoGravelH5')}</h5>
              <p>{t('whatWeDoGravelP')}</p>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="card">
            <img
              src={transport}
              alt={t('whatWeDoTransportH4')}
              className="card-img-top"
            />
            <div className="card-body">
              <h4 className="card-title">{t('whatWeDoTransportH4')}</h4>
              <h5>{t('whatWeDoTransportH5')}</h5>
              <p>{t('whatWeDoTransportP')}</p>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="card">
            <img
              src={landScaping}
              alt={t('whatWeDoEngineeringH4')}
              className="card-img-top"
            />
            <div className="card-body">
              <h4 className="card-title">{t('whatWeDoEngineeringH4')}</h4>
              <h5>{t('whatWeDoEngineeringH5')}</h5>
              <p>{t('whatWeDoEngineeringP')}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
