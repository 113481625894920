import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import email from '../../images/email-green.png';
import lineBlack from '../../images/line-black.png';
import location from '../../images/location-green.png';
import phone from '../../images/phone-green.png';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container className="information">
      <Row>
        <Col>
          <h3>{t('company')}</h3>
          <h2>{t('informationTakeContact')}</h2>
          <img src={lineBlack} alt={t('blackLine')} />
          <h5>{t('informationSubtitle')}</h5>
          <p className="information-name">{t('informationName')}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <img src={email} alt="Email icon" className="icon" />
          <p>{t('contactEmail')}</p>
        </Col>
        <Col lg={4}>
          <img src={phone} alt="Phone icon" className="icon" />
          <p>{t('contactPhone')}</p>
        </Col>
        <Col lg={4}>
          <img src={location} alt="Location icon" className="icon" />
          <p>{t('kynsikangas')}</p>
          <p>{t('otos')}</p>
        </Col>
      </Row>
    </Container>
  );
};
