import i18n, { i18n as i18nType } from 'i18next';
import supportedLanguages from './supported-languages';

export const defaultNamespace = 'common';

export const options = {
  fallbackLng: supportedLanguages.defaultLocale.value,
  ns: [defaultNamespace],
  defaultNS: defaultNamespace,

  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    wait: false,
  },

  initImmediate: false, // Important for SSR to work
};

export default (): i18nType => {
  i18n.init(options);

  return i18n;
};
