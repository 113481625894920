import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';

interface Props {
  className: string;
  children: JSX.Element;
}

export default (props: Props): JSX.Element => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg-hero.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data: any) => {
      const imageData = data.desktop.childImageSharp.fluid;
      const { className, children } = props;
      return (
        <BackgroundImage
          Tag="section"
          className={`background-image-component ${className} `}
          backgroundColor="#ffffff"
          fluid={imageData}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);
