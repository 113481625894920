import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import urls from './urls';

const Header = (): JSX.Element => {
  const [activeClass, setActiveClass] = useState('hide-header-bg');
  const { t } = useTranslation();

  const setClassToHeader = useCallback(() => {
    let localActiveClass = 'normal';
    if (window.scrollY === 0) {
      localActiveClass = 'hide-header-bg';
    }
    setActiveClass(localActiveClass);
  }, [setActiveClass]);

  useEffect(() => {
    setClassToHeader();
    window.addEventListener('scroll', setClassToHeader);
  }, [setClassToHeader]);

  return (
    <Navbar
      bg="light"
      variant="light"
      fixed="top"
      expand="lg"
      className={activeClass}
    >
      <Link to="/" className="navbar-brand">
        {/* {activeClass === 'normal' ? (
          <img src="/" alt={t('headerAlt')} />
        ) : (
          <img src="/" alt={t('headerAlt')} />
        )} */}
        {t('company')}
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Link href={urls.home}>{t('homepage')}</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
