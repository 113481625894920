import { TFunction } from 'i18next';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import Hero from '../page-partials/index/Hero';
import Information from '../page-partials/index/Information';
import WhatWeDo from '../page-partials/index/WhatWeDo';
import WhoWeAre from '../page-partials/index/WhoWeAre';

interface Props {
  pageContext: {
    locale: string;
  };
  t: TFunction;
}

const IndexPage = ({ pageContext: { locale }, t }: Props) => {
  return (
    <Layout>
      <SEO
        title={t('indexTitle')}
        description={t('indexDescription')}
        pageImage="/"
        pageUrl="http://localhost"
        lang={locale}
      />
      <Hero />
      <WhoWeAre />
      <WhatWeDo />
      {/* <MiddleSection />
      <WhereYouCanUse /> */}
      <Information />
    </Layout>
  );
};
export default withI18next({ ns: 'common' })(IndexPage);
