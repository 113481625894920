import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '../images/soraset-logo.png';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg={4}>
            <h5>{t('company')}</h5>
            <p>{t('heroH3')}</p>
            <ul>
              <li>{t('contactName')}</li>
              <li>{t('contactPhone')}</li>
              <li>{t('contactEmail')}</li>
              <li>{t('contactId')}</li>
            </ul>
          </Col>
          <Col lg={3}>
            <h5>{t('footerPlaces')}</h5>
            <ul>
              <li>{t('kynsikangas')}</li>
              <li>{t('otos')}</li>
            </ul>
          </Col>
          <Col lg={5}>
            <img src={logo} alt="Soraset logo" />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">{`©${new Date().getFullYear()} Soraset Oy`}</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
