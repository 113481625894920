import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import lineBlack from '../../images/line-black.png';

export default (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Container className="who-we-are">
      <Row>
        <Col>
          <h3>{t('whoWeAreSubTitle1')}</h3>
          <h2>{t('contactName')}</h2>
          <img src={lineBlack} alt={t('blackLine')} />
          <h5>{t('whoWeAreSubtitle2')}</h5>
          <p>{t('whoWeAreParagraph1')}</p>
          <p>{t('whoWeAreParagraph2')}</p>
        </Col>
      </Row>
    </Container>
  );
};
